import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dashbot is constantly looking for ways to improve our analytics and the insights that can be gained from conversations. We recently launched a few new products that will allow our customers to better understand user behavior, improve the chatbot response effectiveness, and increase user satisfaction. See how the new Dashbot products can be used to craft a better customer experience.`}</p>
    <h2>{`Integration with Genesys`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/15214449/dashbot-genesys-partnership.png",
        "alt": null
      }}></img></p>
    <p>{`Conversational AI is really taking hold in customer service. According to Gartner, 15 percent of all customer service interactions will be `}<a parentName="p" {...{
        "href": "https://www.gartner.com/smarterwithgartner/4-trends-gartner-hype-cycle-customer-service-customer-engagement/"
      }}>{`completely handled by AI`}</a>{`, which is why Dashbot is happy to announce its integration with `}<a parentName="p" {...{
        "href": "https://appfoundry.genesys.com/"
      }}>{`Genesys`}</a>{`, a global leader in omnichannel customer experience solutions.`}</p>
    <p>{`Through the integration with Genesys, customers will be able to analyze and track live agent conversations as well as more seamlessly handle live agent takeovers of chat sessions.`}</p>
    <h2>{`Goal Tracking`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/15215255/escalation-600.png",
        "alt": null
      }}></img></p>
    <p>{`Any chatbot or voice skill is built with goals in mind, and with our Goal Tracking feature, you’re able to keep a close eye on your metrics to better achieve your goals.`}</p>
    <p>{`For example, if one of the KPIs of your chatbot is containment, you can easily see how many people are escalating to a live agent versus staying within the chatbot experience, as well as view the paths that lead to escalation. The insights can be used to improve the user experience to increase customer satisfaction.`}</p>
    <p>{`There is no limit to the possibilities of what you can track. You can track purchases, signups, or any other customer goal with our Goal Tracking feature.`}</p>
    <h2>{`Transcript Search`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/15220228/search.png",
        "alt": null
      }}></img></p>
    <p>{`Dashbot maintains and displays the full transcripts of each conversation your customers have, providing a wealth of information. However, sifting through every single transcript to find what you’re looking for can be a time consuming task, especially if you have a high number of sessions. With Transcript Search, it’s as simple as typing in a phrase or keyword into a search bar to narrow down the conversations that are of interest to you. This feature allows you to search through full text, Intents, users, session length, and sentiment in all conversations, to better understand how users are interacting with your chatbot.`}</p>
    <p>{`Anomalous Session Detection`}</p>
    <hr></hr>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/15215929/anamolous.png",
        "alt": null
      }}></img></p>
    <p>{`With Anomalous Session Detection, Dashbot can automatically highlight unusual conversations with unusual length, unique phrases, repeated text, extreme sentiment, and more. All these characteristics point to where your user might be experiencing obstacles in the chatbot, or something outside the norm that could benefit from further review. By highlighting these instances, you’ll know where to quickly look to gain insight into your users, without having to pore through thousands of transcripts.`}</p>
    <h2>{`Phrase Clustering`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/15215632/phrase-cluster.png",
        "alt": null
      }}></img></p>
    <p>{`Building for conversational interfaces can be challenging. Users can say whatever they like to a chatbot – it’s all unstructured. Training a natural language model can be a difficult undertaking but very necessary to the success of your chatbot.`}</p>
    <p>{`It’s important to view the messages users are sending to see where the chatbot responses may be breaking down – through unhandled or mishandled Intents. However, manually poring through individual messages can be a time consuming, labor intensive task.`}</p>
    <p>{`Dashbot’s Phrase Clustering automatically groups semantically similar messages together whether you are using an NLP engine or not. With Phrase Clustering, you can quickly see where the chatbot responses are breaking down through mishandled and unhandled Intents, saving a significant amount of time. The insights can be easily used to improve the NLP training model.`}</p>
    <h2>{`Watch the announcements from SuperBot`}</h2>
    <p>{`Watch Dashbot CPO Dennis Yang walk through all the new Dashbot products below.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=ZBBcXET9UGA"
      }}>{`https://www.youtube.com/watch?v=ZBBcXET9UGA`}</a></p>
    <h2>{`Interested in a demo?`}</h2>
    <p>{`Would you like to see a demo of these premium features?`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+dashbot+customer+service+features"
      }}><br parentName="a"></br>
        {`Contact us for a Demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      